@import 'scss/variables.scss';

.page_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
        margin-top: $spacing-xxl;
        margin-bottom: $spacing-3xl;
    }

    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
    }

    .nav_links {
        display: flex;
        height: $size-lg;
        justify-content: center;
        list-style: none;
        gap: $spacing-base;
        width: 100%;
        text-transform: none;
        padding: 0;

        li {
            position: relative;
            display: flex;
            flex-basis: 12rem;
            flex-grow: 0;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;
            border-top: 0.1rem solid $color-white;
            padding: $spacing-xs $spacing-md 0 $spacing-md;
        }
        
        a {
            font-family: $font-family-lexend;
            font-size: $font-size-sm;
            color: $color-white;
            text-decoration: none;
            white-space: nowrap;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 50%;
                height: 0.3rem;
                background-color: $color-white;
                transform: scaleX(0);
            }
            
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: 50%;
                height: 0.3rem;
                background-color: $color-white;
                transform: scaleX(0);
            }
            
            &:hover, &[aria-current="page"] {
                &:before {
                    transform: scaleX(1);
                }
        
                &:after {
                    transform: scaleX(1);
                }
            }
            &[aria-current="page"] {
                font-weight: $font-weight-regular;
            }
        }
    }
}

.main_content {
    padding: $spacing-lg $spacing-base;

    @include tablet-sm-up {
        height: auto;
        padding: $spacing-xxl 0;
    }
}
@media screen and (max-width: 767px) {
    
    .column {
        display: flex;
        flex-direction: row;

    
        .logo {
            display: flex;
            padding-left: 35px;
            
        }
    }
}


