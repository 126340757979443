@import 'scss/variables.scss';

.container {
    width: 100vw;
    padding-left: 90px;
    padding-right: 90px;
}
.image {
    cursor: pointer;
    display: block;
    width: 100%;
}