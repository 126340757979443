@import 'scss/variables.scss';

.homepage_slider_container {
	width: 80vw;

	:global {

		.slick-prev,
		.slick-next {
			z-index: 1;
			opacity: 0;
			transition: .5s all;
		}

		.slick-prev {
			left: -30px;
		}

		.slick-next {
			right: -20px;
		}

		.slick-prev:before,
		.slick-next:before {
			color: $color-white;
			font-size: $font-size-xl;
		}
	}

	// &:hover {
	// 	:global {

	// 		.slick-prev,
	// 		.slick-next {
	// 			opacity: 1;
	// 		}
	// 	}
	// }
}


.img {
	display: flex;
	margin: 0 auto;
	height: auto;
	width: 100%;
	max-width: 1000px;
}