@import 'scss/variables.scss';

.page_wrapper {
  display: flex;
  flex-direction: column;
  gap: 136px;
  margin: 0 16px;

  .paragraph {
    line-height: 35px;
  }

  .column {
    display: flex;
    gap: 64px;
    .image_wrapper {
      max-width: 442px;
      width: 100%;
      height: 100%;
      max-height: 295px;
      .image {
        height: 100%;
        width: 100%;
      }
    }
  }

  .h1 {
    font-family: $font-family-poppins;
    font-weight: $font-weight-regular;
    font-size: $font-size-lg;
    line-height: 4.8rem;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .contact {
    max-width: 464px;
    max-height: 295px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: $color-white;
    font-family: $font-family-poppins;
    font-weight: $font-weight-regular;
    font-size: $font-size-base;
    line-height: 2.4rem;
  }

  .fields_wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-md;
  }

  .button_wrapper {
    display: flex;
    justify-content: center;
    padding: 12px 60px 12px 60px;
    .submit_button {
      background: $color-black;
      border: 1px solid $color-white;
      border-radius: $border-radius-md;
      color: $color-white;
      width: 219px;
      height: 51px;
      cursor: pointer;

      &:disabled {
        background-color: $color-white;
        color: $color-black
      }

      &:hover {
        background-color: $color-white;
        color: $color-black
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .column {
    flex-direction: column;
  }
}
