@import 'scss/variables.scss';

.footer_wrapper {
    bottom: $spacing-base;
    display: flex;
    flex-direction: column;
    position: relative;

    .social_media {
        display: flex;
        justify-content: center;
    
        .instagram {
            color: $color-white;
            padding-right: 4px;
        }
    }
    
    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-white;
        font-family: $font-family-base;
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
        line-height: 2.4rem;
    }

    @include tablet-sm-up {
        padding: $spacing-sm $spacing-lg;
    }
}