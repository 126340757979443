// Font families
$font-family-poppins: 'Poppins',
    sans-serif;
$font-family-brygada: 'Brygada 1918', serif;
$font-family-lexend: 'Lexend Exa', sans-serif;
$font-family-base: $font-family-poppins;


// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Font sizes
$font-size-xxs: 1rem;
$font-size-xs: 1.2rem;
$font-size-sm: 1.44rem;
$font-size-base: 1.6rem;
$font-size-md: 2.074rem;
$font-size-lg: 2.488rem;
$font-size-xl: 2.986rem;

// Line heights
$line-height-sm: 1.25;
$line-height-base: 1.5;

// Colors
$color-black: #000000;
$color-bronze: #DEA876;
$color-gray-medium: #A7A7A7;
$color-gray-light: #DBDBDB;
$color-white: #ffffff;

$color-error: red;

$color-heading: $color-white;
$color-body-dark: $color-black;
$color-body-light: $color-gray-light;
$color-background: $color-black;

// Container
$container-width: 166rem;

// Size
$size-xxs: 0.4rem;
$size-xs: .8rem;
$size-sm: 1.2rem;
$size-base: 1.6rem;
$size-md: 2.4rem;
$size-lg: 3.2rem;
$size-xl: 4rem;

// Spacing
$spacing-xxs: 0.4rem;
$spacing-xs: .8rem;
$spacing-sm: 1.2rem;
$spacing-base: 1.6rem;
$spacing-md: 2.4rem;
$spacing-lg: 3.2rem;
$spacing-xl: 4rem;
$spacing-xxl: 4.8rem;
$spacing-3xl: 5.6rem;

// Breakpoints
$breakpoint-mobile-sm: 320;
$breakpoint-mobile: 540;
$breakpoint-tablet-sm: 768;
$breakpoint-tablet: 1024;
$breakpoint-laptop: 1200;
$breakpoint-desktop: 1800;

// Border radius
$border-radius-xs: .4rem;
$border-radius-sm: .8rem;
$border-radius-md: 1.2rem;


@import 'mixins/breakpoints.scss';
@import 'mixins/typography.scss';