@import 'scss/variables.scss';

.menu_toggle {
    display: block;
    position: relative;
    top: 50px;
    left: 80px;
    z-index: 1;

    a {
        font-family: $font-family-poppins;
        text-decoration: none;
        color: $color-black; 
        transition: color 0.3s ease;
        &:hover {
            color: $color-error;
        }
    }

    input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: -5px;
        cursor: pointer;
        opacity: 0; /* hide this */
        z-index: 2; /* and place it over the hamburger */
        -webkit-touch-callout: none;
        &:checked ~ span {
            opacity: 1;
            transform: rotate(45deg) translate(-2px, -1px);
            background: $color-black;
        }
        &:checked ~ span:nth-last-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }
        &:checked ~ span:nth-last-child(2) {
            transform: rotate(-45deg) translate(0, -1px);
        }
        &:checked ~ ul {
            transform: none;
        }
    }

    span {
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: $color-white;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        opacity 0.55s ease;
        &:first-child {
            transform-origin: 0% 0%;
        }
        &:nth-last-child(2) {
            transform-origin: 0% 100%;
        }
    }

}

.menu {
    position: absolute;
    width: 300px;
    height: 100vh;
    margin: -100px 0 0 -130px;
    padding: 50px;
    padding-top: 125px;
    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

    li {
        padding: 10px 0;
        font-size: $font-size-md;
    }
}
