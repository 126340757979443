@import 'scss/variables.scss';

.page_wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 16px;

  .column {
    display: flex;
    gap: 64px;
    .image_wrapper {
      max-width: 442px;
      width: 100%;
      height: 100%;
      max-height: 645px;
      .image {
        height: 590px;
        width: auto;
      }
    }
  }

  .about {
    max-width: 442px;
    width: 100%;
    height: 100%;
    max-height: 645px;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    color: $color-white;
    font-family: $font-family-poppins;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-sm;
  }

  .h1 {
    font-family: $font-family-poppins;
    font-weight: $font-weight-regular;
    font-size: $font-size-xl;
    line-height: 4.8rem;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .paragraph {
    line-height: 60px;
  }
}

@media screen and (max-width: 5120px) {
  .image {
    height: 590px;
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .column {
    flex-direction: column;
      }
  .image {
    display: none;
  }
  .image2 {
    display: block;
  }
} 

@media screen and (max-width: 600px) {
  .page_wrapper {
    flex: 25%;
    padding: 20px;
  }
  .image {
    display: none;
  }
  .image2 {
    display: flex;
    width: auto;
    height: auto;
  }
} 
