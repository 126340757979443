@import './variables.scss';

html {
    box-sizing: border-box;
    font-size: 62.5%;
    background-color: $color-background;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    overflow-x: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Brygada+1918:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&family=Lexend+Exa:wght@300;400;500;600;700&display=swap');