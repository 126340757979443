@import 'scss/variables.scss';

.lightbox_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .lightbox_modal {
    background: black;
    padding: 20px;
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .lightbox_close {
    position: absolute;
    top: 10px;
    font-size: 40px;
    cursor: pointer;
    color: white;
  }
  
  .lightbox_content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .lightbox_image {
    max-width: 50vw;
    max-height: 100vh;
    opacity: 0;
    transition: opacity 0.3s ease-in-out; 
  }
  
  .lightbox_nav_prev {
    position: absolute;
    top: 50%;
    font-size: 40px;
    cursor: pointer;
    transform: translateY(-100%);
    z-index: 10000;
    left: 20px;
    color: white;
  }

  .lightbox_nav_next {
    position: absolute;
    top: 50%;
    font-size: 40px;
    cursor: pointer;
    transform: translateY(-100%);
    z-index: 10000;
    right: 20px;
    color: white;
  }
