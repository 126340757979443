body,
input,
textarea,
select,
button,
::placeholder {
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $color-body-light;
}

body{
    font-weight: $font-weight-light;
}

h1, h2{
    font-family: $font-family-brygada;
    font-weight: $font-weight-bold;
    color: $color-heading;
}

h3, h4, h5, h6{
    font-family: $font-family-poppins;
    font-weight: $font-weight-semibold;
    color: $color-heading;
}